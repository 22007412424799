import React                from 'react'
import Img                    from "gatsby-image"
import cx                   from 'utils/classnames'
import { filter, map }              from 'lodash-es'
import {
  FaRegClock,
  FaCheck,
  FaShuttleVan,
  FaRegCommentDots,
  FaAccessibleIcon
}                           from 'react-icons/fa'
import {
  Container,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
}                           from 'react-bootstrap'
import {
  StickyContainer,
  Sticky
}                           from 'react-sticky'
// import Icon                 from "entrada-ui/Icon"
import RawHtml              from 'entrada-ui/RawHtml'
import Typography           from 'entrada-ui/Typography'
import {
  useWindowSize
}                           from 'utils'
import Accordion            from 'entrada-ui/Accordion'
import BookingWizard         from 'components/BookingWizard'
// import BookContact          from 'components/BookContact'
import Gallery              from "components/Gallery"
import Link                 from 'components/Link'
import PhoneContactBlock    from 'components/PhoneContactBlock'
import PreviewLoader        from 'components/PreviewLoader'
// import SimpleList           from 'components/SimpleList'
// import Timeline             from 'components/Timeline'
import TourCards            from 'components/TourCards'
import PageTitle            from 'components/PageTitle'
import Seo                  from 'components/Seo'

import styles               from './styles.module.scss'

const TourCard = TourCards.CardWp

let prevScroll = 0

const TourTemplate = (props) => {
  const {
    pageContext: { data }
  } = props

  
  const {
    siteMetadata,
    yoast_meta,
    wordpress_id,
    title,
    slug,
    content,
    destination,
    allDestinations,
    acf: {
      tour_code,
      gallery,
      schedule,
      accordion,
      related_tours,
      price,
      booking_alert
    }
  } = data

  const wSize = useWindowSize()

  const galleryImages = gallery.map(item => item.localFile.childImageSharp.fluid)

  const ref = React.useRef(null)
  const [headerOffsetTop, setHeaderOffsetTop] = React.useState(0)
  const [scrollingUp, setScrollingUp] = React.useState(false)
  const [currentPageIndex, setCurrentPageIndex] = React.useState(0)

  const otherDestinations = filter(allDestinations, (otherDestination) => otherDestination.wordpress_id !== destination.wordpress_id)

  React.useEffect(() => {
      const handleScrollWindow = (ev) => {
          const newScrollingUp = prevScroll > window.pageYOffset
          if(newScrollingUp !== scrollingUp) {
            setScrollingUp(newScrollingUp)
          }

          prevScroll = window.pageYOffset
      }

      window.addEventListener('scroll', handleScrollWindow)

      return () => window.removeEventListener('scroll', handleScrollWindow)
  }, [scrollingUp, headerOffsetTop])

  React.useEffect(() => {
      if(ref) {
        setHeaderOffsetTop(ref.current.getBoundingClientRect().top)
      }
  }, [ref])


  return (
    <>
      <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
      <PreviewLoader {...props} />
      <PageTitle title={title} />
      <div className={styles.body}>

        <Container ref={ref}>
            <StickyContainer>
              <Row>
                <Col xs={12} lg={7} xl={8} className="pl-0 pr-0 pl-lg-2 pr-lg-2">
                  <Gallery images={galleryImages} />
                </Col>

                <Col xs={12} lg={5} xl={4} className="position-relative">
                  <Sticky bottomOffset={75} disableCompensation>
                    {({ style, isSticky, distanceFromTop, calculatedHeight }) => (
                      <div
                        className={cx(
                          styles.bookingWizardSticky,
                          (!wSize.isMobile && !wSize.isTablet) && isSticky && styles.sticky,
                          currentPageIndex === 1 && styles.optionsStep,
                          scrollingUp && styles.scrollingUp,
                        )}
                        style={(!wSize.isMobile && !wSize.isTablet) ? {
                          ...style,
                          // top: (scrollingUp ? headerOffsetTop : style.top)
                        } : undefined}
                        >
                        {booking_alert && <div className={styles.cardStamp}>{booking_alert}</div>}
                        <BookingWizard
                          className={styles.bookingWizard}
                          tourCode={tour_code}
                          tourSellingFast={false}
                          destination={destination}
                          relatedTours={[]}
                          cmsTour={data}
                          onPageChange={(page) => setCurrentPageIndex(page)}
                        />
                      </div>
                    )}
                  </Sticky>
              </Col>
            </Row>

            {/* TODO:: FIX_MUBASHIR_ALI */}
            {/*  className={styles.content} */}
            <div className={styles.content}>
              <Row>
                <Col xs={12} lg={7} xl={8}>
                  <Typography className="text-color-brand-color" variant="h3">
                    Overview
                  </Typography>
                  <RawHtml html={content} />
                </Col>
              </Row>
            </div>

            <Row>
              <Col xs={12} lg={7} xl={8}>
                <Accordion className={styles.accordion}>

                    <Accordion.Item key="itinerary">
                      <Accordion.Toggler className={styles.itineraryToggler}>
                        <Accordion.Title className="text-bold">Itinerary</Accordion.Title>
                      </Accordion.Toggler>

                      <Accordion.Content className={styles.itineraryContent}>
                      {/* TODO: create ItineraryList component */}
                      <ul className={styles.itineraryList}>
                        {map(schedule, (item) => (
                          <li className={styles.itineraryListItem}>
                            {item.image &&
                            <Img fluid={item.image.localFile.childImageSharp.fixed} className={styles.itineraryIcon} />
                            }
                            <span className={styles.itineraryTime}>{item.time ? item.time : '─'}</span>
                            <p className={styles.itineraryDescription}>{item.description}</p>
                          </li>
                        ))}
                      </ul>
                      </Accordion.Content>
                    </Accordion.Item>

                </Accordion>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={7} xl={8}>
                <Accordion className={styles.accordion}>
                  {map(accordion, (item, idx) => (
                    <Accordion.Item key={idx}>
                      <Accordion.Toggler>
                        <Accordion.Title className="text-color-brand-color text-bold">{item.title}</Accordion.Title>
                      </Accordion.Toggler>

                      <Accordion.Content>
                        <RawHtml html={item.body} />
                      </Accordion.Content>
                    </Accordion.Item>
                  ))}
                </Accordion>

                <PhoneContactBlock />
              </Col>
            </Row>

          </StickyContainer>
        </Container>

        <div className={cx(styles.relatedTours, 'pt-5 pb-5')}>
          <Container>
            <Row>
              <Col>
                <Typography className="text-center text-transform-none text-color-brand-color" variant="h3">
                  Combos & other awesome tours
                </Typography>

                <TourCards showArrows={false}>
                {map(related_tours, (tour, idx) => (
                    <TourCard
                        key={idx}
                        {...tour}
                    />
                ))}
                </TourCards>
              </Col>
            </Row>
          </Container>
        </div>

        <div className={cx(styles.otherDestinations, 'pt-5 pb-5')}>
          <ul>
            {map(otherDestinations, (destination, idx) => (
              <li key={idx}>
                <Link to={destination.url}>
                  {destination.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

// const TourTemplate = (props) => {
//   const {
//     pageContext: { data }
//   } = props

//   const {
//     siteMetadata,
//     title,
//     destination,
//     yoast_meta,
//     acf: {
//       tour_name,
//       tour_code,
//       overview,
//       departure,
//       explore, // "Explore similar things to do"
//       card_features,
//       tour_duration_hours,
//       tour_duration_minutes,
//       tour_inclusions = [],
//       tour_itinerary = [],
//       tour_selling_fast,
//       related_tours,
//       gallery = [],
//       what_to_expect,
//       hotel_pickup,
//       additional_info,
//       cancellation_policy,
//       offered_languages,
//       info_box_time,
//       info_box_hotel,
//       info_box_language,
//       info_box_wheelchair,
//       combo_tour
//     }
//   } = data

//   const wSize = useWindowSize()

//   const galleryImages = gallery.map(item => item.localFile.childImageSharp.fluid)

//   const ref = React.useRef(null)
//   const [headerOffsetTop, setHeaderOffsetTop] = React.useState(0)
//   const [scrollingUp, setScrollingUp] = React.useState(false)
//   const [currentPageIndex, setCurrentPageIndex] = React.useState(0)

//   React.useEffect(() => {
//       const handleScrollWindow = (ev) => {
//           const newScrollingUp = prevScroll > window.pageYOffset
//           if(newScrollingUp !== scrollingUp) {
//             setScrollingUp(newScrollingUp)
//           }

//           prevScroll = window.pageYOffset
//       }

//       window.addEventListener('scroll', handleScrollWindow)

//       return () => window.removeEventListener('scroll', handleScrollWindow)
//   }, [scrollingUp, headerOffsetTop])

//   React.useEffect(() => {
//       if(ref) {
//         setHeaderOffsetTop(ref.current.getBoundingClientRect().top)
//       }
//   }, [ref])

//   return (
//     <>
//       <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
//       <PreviewLoader {...props} />
//       <Container ref={ref}>
//           <Row>
//             <Col xs={12} lg={7} xl={8}>
//               <div className={styles.breadcrumb}>
//                 <Link to={destination.url}>{destination.acf.page_heading}</Link>
//               </div>
//               <h1 className={styles.mainTitle}>{tour_name}</h1>
//               <Typography className={styles.departure} variant="subtitle">from {departure}</Typography>
//             </Col>
//             <Col xs={12} lg={5} xl={4}>
//               <BookContact className={styles.topBookContact} />
//             </Col>
//           </Row>

//         <StickyContainer>
//           <Row>
//             <Col xs={12} lg={7} xl={8} className="pl-0 pr-0 pl-lg-2 pr-lg-2">
//               <Gallery images={galleryImages} />
//             </Col>

//             <Col xs={12} lg={5} xl={4} className="position-relative">
//               <Sticky bottomOffset={75} disableCompensation>
//                 {({ style, isSticky, distanceFromTop, calculatedHeight }) => (
//                   <div
//                     className={cx(
//                       styles.bookingWizardSticky,
//                       (!wSize.isMobile && !wSize.isTablet) && isSticky && styles.sticky,
//                       currentPageIndex === 1 && styles.optionsStep,
//                       scrollingUp && styles.scrollingUp,
//                     )}
//                     style={(!wSize.isMobile && !wSize.isTablet) ? {
//                       ...style,
//                       // top: (scrollingUp ? headerOffsetTop : style.top)
//                     } : undefined}
//                     >
//                     <BookingWizard
//                       className={styles.bookingWizard}
//                       tourCode={tour_code}
//                       tourSellingFast={parseInt(tour_selling_fast)}
//                       destination={destination}
//                       relatedTours={related_tours}
//                       cmsTour={data}
//                       onPageChange={(page) => setCurrentPageIndex(page)}
//                     />
//                   </div>
//                 )}
//               </Sticky>
//             </Col>
//           </Row>

//           <Row>
//             <Col xs={12} lg={7} xl={8}>
//               <hr />

//               <SimpleList className={styles.featuresList}>
//                 {info_box_time &&
//                 <SimpleList.Item icon={<FaRegClock className={styles.featureIcon} />} >
//                   { combo_tour ?
//                     'Two-day tour combo'
//                     :
//                     `${tour_duration_hours} hours ` + (tour_duration_minutes != null ? `${tour_duration_minutes} minutes` : '')
//                   }
//                 </SimpleList.Item>
//                 }

//                 {info_box_hotel &&
//                 <SimpleList.Item icon={<FaShuttleVan className={styles.featureIcon} />} >
//                   Hotel pickup/drop-off offered
//                 </SimpleList.Item>
//                 }

//                 {info_box_language &&
//                 <SimpleList.Item icon={<FaRegCommentDots className={styles.featureIcon} />} >
//                   Offered in: English and&nbsp;
//                   <OverlayTrigger
//                       placement="top"
//                       overlay={
//                       <Tooltip>
//                           {offered_languages.join(', ')}
//                       </Tooltip>
//                       }
//                   >
//                       <span style={{ cursor: 'help' }}>{offered_languages.length} more</span>
//                   </OverlayTrigger>
//                 </SimpleList.Item>
//                 }

//                 {info_box_wheelchair &&
//                 <SimpleList.Item icon={<FaAccessibleIcon className={styles.featureIcon} />} >
//                   Wheelchair
//                 </SimpleList.Item>
//                 }

//               </SimpleList>

//               <hr />

//               <h3>Overview</h3>
//               {/* TODO This must be a "ReadMore" component, when you click you can read more. */}
//               <RawHtml html={overview} />


//               <Accordion className={styles.accordion}>
//                 {!!tour_inclusions.length &&
//                 <Accordion.Item>
//                   <Accordion.Toggler>
//                     <Accordion.Title>What's Included</Accordion.Title>
//                   </Accordion.Toggler>

//                   <Accordion.Content>
//                     <SimpleList>
//                       {map(tour_inclusions, (item, idx) => (
//                         <SimpleList.Item
//                           key={idx}
//                           icon={(
//                             <StringToIcon
//                               className={cx(
//                                 styles.inclusionIcon,
//                                 item.inclusion_icon.includes('times') && styles.excluded
//                               )}
//                               icon={item.inclusion_icon}
//                             />
//                           )}
//                         >
//                           {item.inclusion_description}
//                         </SimpleList.Item>
//                       ))}
//                     </SimpleList>
//                   </Accordion.Content>
//                 </Accordion.Item>
//                 }

//                 {!!tour_itinerary.length &&
//                 <Accordion.Item>
//                   <Accordion.Toggler>
//                     <Accordion.Title>Itinerary</Accordion.Title>
//                   </Accordion.Toggler>

//                   <Accordion.Content>
//                     <Timeline>
//                       {map(tour_itinerary, (item, idx) => (
//                         <Timeline.Item
//                             key={idx}
//                             time={item.item_time}
//                             icon={<StringToIcon icon={item.item_icon} />}
//                         >
//                           {item.item_description}
//                         </Timeline.Item>
//                       ))}
//                     </Timeline>
//                   </Accordion.Content>
//                 </Accordion.Item>
//                 }

//                 {what_to_expect &&
//                 <Accordion.Item>
//                   <Accordion.Toggler>
//                     <Accordion.Title>What to Expect</Accordion.Title>
//                   </Accordion.Toggler>

//                   <Accordion.Content>
//                     <RawHtml html={what_to_expect} />
//                   </Accordion.Content>
//                 </Accordion.Item>
//                 }

//                 {hotel_pickup &&
//                 <Accordion.Item>
//                   <Accordion.Toggler>
//                     <Accordion.Title>Hotel Pickup/Drop-off</Accordion.Title>
//                   </Accordion.Toggler>

//                   <Accordion.Content>
//                     <RawHtml html={hotel_pickup} />
//                   </Accordion.Content>
//                 </Accordion.Item>
//                 }

//                 {additional_info &&
//                 <Accordion.Item>
//                   <Accordion.Toggler>
//                     <Accordion.Title>Additional Info</Accordion.Title>
//                   </Accordion.Toggler>

//                   <Accordion.Content>
//                     <RawHtml html={additional_info} />
//                   </Accordion.Content>
//                 </Accordion.Item>
//                 }

//                 {cancellation_policy &&
//                 <Accordion.Item>
//                   <Accordion.Toggler>
//                     <Accordion.Title>Cancellation Policy</Accordion.Title>
//                   </Accordion.Toggler>

//                   <Accordion.Content>
//                     <RawHtml html={cancellation_policy} />
//                   </Accordion.Content>
//                 </Accordion.Item>
//                 }
//               </Accordion>

//               <h3>Questions?</h3>
//               <BookContact big={true} />
//               <p className={styles.refCode}>
//                 Reference tour code: <b>{tour_code}</b>
//               </p>
//             </Col>
//           </Row>
//         </StickyContainer>
//       </Container>

//       {related_tours.length &&
//       <div className={styles.similarToursWrapper}>
//         <Container>
//           <h3>Similar Tours and Activities</h3>

//           <TourCards>
//             {map(related_tours, (tour, idx) => (
//             <TourCard
//                 key={idx}
//                 {...tour}
//             />
//             ))}
//             </TourCards>
//         </Container>
//       </div>
//       }

//       <Container className="pb-4 pt-2">
//         <h3>Explore Similar Things to Do</h3>
//         <div className={styles.similarThings}>
//           {map(explore, (theme, idx) => (
//             <Link key={idx} className="btn btn-lg btn-outline-primary" to={theme.url}>{theme.title}</Link>
//           ))}
//         </div>
//       </Container>
//     </>
//   )
// }

export default TourTemplate

import React      from 'react'
import { map }    from 'lodash-es'
import Lightbox   from 'react-image-lightbox'
import {
  FaCamera,
}                           from 'react-icons/fa'
import {
  useWindowSize
}                 from 'utils'
import cx         from 'utils/classnames'
import Icon       from 'entrada-ui/Icon'
import Slideshow  from 'components/Slideshow'
import            'react-image-lightbox/style.css'
import styles     from './styles.module.scss'

const RemainingOverlay = ({ total }) => (
  <div className={styles.remainingOverlay}>
    <Icon className={styles.remainingOverlayIcon}>
        <FaCamera /> {total}
    </Icon>
  </div>
)


const Gallery = ({
  images = [],
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [currentIndex, setCurrentIndex] = React.useState(0)

  const wSize = useWindowSize()

  const handleClickThumb = (idx) => {
    return (ev) => {
      setCurrentIndex(idx)
      setIsOpen(true)
    }
  }

  const totalRemaining = images.length - 5

  return (
    <>
      <div>
        <Slideshow
          className={styles.slideshow}
          noTransition={wSize.width >= 1200}
          showIndicators={false}
          ViewProps={{
            className: styles.slideshowView
          }}
        >
            {map(images, (image, idx) => (
              <Slideshow.Slide
                className={cx(styles.slide, idx > 4 && styles.hiddenSlide)}
                key={idx}
                onClick={handleClickThumb(idx)}
              >
                <div className={styles.slideContent}>
                  <Slideshow.Img
                    className={styles.slideImg}
                    fluid={{
                      ...image,
                      aspectRatio: 4/3
                    }}
                  />

                  {idx === 4 &&
                  <RemainingOverlay total={totalRemaining} />
                  }
                </div>
              </Slideshow.Slide>
            ))}
        </Slideshow>
      </div>

      {isOpen && (
      <Lightbox
        mainSrc={images[currentIndex].src}
        nextSrc={images[(currentIndex + 1) % images.length].src}
        prevSrc={images[(currentIndex + images.length - 1) % images.length].src}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() => setCurrentIndex((currentIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => setCurrentIndex((currentIndex + 1) % images.length)}
      />
      )}
    </>
  )
}

export default Gallery
